<template>
	<div class="container" ref="getheight">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">账单</div>
		</div>

		<div class="list">
			<van-list v-model="loading" @load="getLog" :finished="finished" finished-text="没有更多了" :error.sync="error"
				error-text="请求失败，点击重新加载">
				<div class="flex-row align-center space-between item" v-for="(item,index) in list" v-bind:key="index">
					<div class="flex-row align-center">
						<div v-if="item.bussinessType == 1" class="rt-img">销</div>
						<div v-else-if="item.bussinessType == 2" class="rt-img4">抽</div>
						<div v-else-if="item.bussinessType == 3" class="rt-img2">提</div>
						<div v-else-if="item.bussinessType == 4" class="rt-img3">充</div>
						<div v-else-if="item.bussinessType == 5" class="rt-img4">批</div>
						<div class="flex-column">
							<div class="item-name">{{item.recordSummary}}</div>
							<div class="item-date">{{item.recordTime}}</div>
						</div>
					</div>
					<div class="item-num">
						{{item.increaseType== 2?'-':''}}
						{{item.points}}
					</div>
				</div>
			</van-list>
		</div>


	</div>
</template>

<script>
	import util from '../../config/util.js';
	import config from '../../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import Vue from 'vue';

	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished: false,
				error: false
			}
		},
		methods: {

			getLog() {
				this.loading = true;
				const that = this;
				this.axios.post(config.pointsRequest +
					`/point-api/rest/pointUserRecord/pageList?pageNum=${that.pageNum}&pageSize=${that.pageSize}&orderByColumn=recordTime&isAsc=desc`, {}, {
						headers: {
							'Content-Type': 'application/json',
							'token': this.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						let list = that.list;
						let newList = response.data.data.rows;
						console.log()
						if (newList.length < that.pageSize) that.finished = true;
						for (let i in newList) {
							let createTime = newList[i].recordTime.replace(/-/g, "/");
							newList[i].recordTime = util.formatDate(createTime)
						}
						list = list.concat(newList);
						that.list = list;
						that.pageNum = that.pageNum + 1;
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						this.error = true;
					}
					this.loading = false;
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},

		},
		mounted() {
			util.wxConfig();
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		min-height: 100%;
		overflow: visible;

		box-sizing: content-box;
	}

	.nav {
		position: relative;
	}

	.date {
		width: 200px;
		height: 50px;
		background: #6236FF;
		border-radius: 34px;
		font-size: 24px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		position: absolute;
		right: 30px;
		top: 21px;
	}

	.list {
		width: 750px;
	}

	.item {
		width: 750px;
		height: 165px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
		padding: 0 40px;
		box-sizing: border-box;
	}

	.item-icon {
		width: 67px;
		height: 67px;
		margin-right: 30px;
	}

	.item-name {
		font-size: 30px;
		color: #000000;
		line-height: 30px;
	}

	.item-date {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-top: 25px;
	}

	.item-num {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
	}

	.rt-img {
		width: 67px;
		height: 67px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 67px;
		border-radius: 100%;
		background: gray;
		margin-right: 30px;
	}

	.rt-img2 {
		width: 67px;
		height: 67px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 67px;
		border-radius: 100%;
		background: #F7B500;
		margin-right: 30px;
	}
	.rt-img3 {
		width: 67px;
		height: 67px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 67px;
		border-radius: 100%;
		background: rgba(250,100,0);
		
		margin-right: 30px;
	}
	.rt-img4 {
		width: 67px;
		height: 67px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 67px;
		border-radius: 100%;
		background: rgba(98,58,255);
		margin-right: 30px;
	}
</style>
